var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_vm.trust && [0, 2].includes(_vm.trust.status) ? _c('div', {
    staticClass: "card-body text-center"
  }, [_c('h5', {
    staticClass: "text-center mb-5"
  }, [_vm._v(_vm._s(_vm.$t("verification")))]), _vm.trust && _vm.trust.user && _vm.trust.status == 1 ? _c('div', [_vm._v(" " + _vm._s(_vm.$t("confirmation-sent-to-donor")) + " ")]) : _vm._e(), _vm.trust && _vm.trust.status == 2 ? _c('div', {
    staticClass: "row d-flex justify-content-center mt-3"
  }, [_vm.userRole != 'Client' ? _c('div', {
    staticClass: "form-group",
    staticStyle: {
      "width": "40%"
    }
  }, [_c('label', {
    attrs: {
      "for": "upload-photo-2"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("proof-of-payment")) + " ")]), _c('div', {
    staticClass: "card rounded shadow bg-dark"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center bd-highlight",
    staticStyle: {
      "height": "150px"
    }
  }, [_vm.receiptShow.imageUrl ? _c('label', {
    staticStyle: {
      "border-radius": "5px",
      "margin-bottom": "0",
      "background-position": "center",
      "background-repeat": "no-repeat",
      "background-size": "cover",
      "height": "100%",
      "width": "100%"
    },
    style: 'background-image: url(' + _vm.receiptShow.imageUrl + ');',
    attrs: {
      "for": "upload-photo-2"
    }
  }) : _vm._e()])]), _c('b-button', {
    staticClass: "mt-2 btn-sm btn-primary",
    on: {
      "click": _vm.showReceipt
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("view")) + " ")])], 1) : _vm._e()]) : _vm._e(), _vm.trust && _vm.trust.status == 0 ? _c('div', [_c('div', {
    staticClass: "card card-custom gutter-b"
  }, [_c('div', {
    staticClass: "card-header"
  }, [_c('div', {
    staticClass: "card-title m-0"
  }, [_c('h5', {
    staticClass: "card-label m-0"
  }, [_vm._v(_vm._s(_vm.$t("payment1")))])])]), _vm.userRole != 'Client' ? _c('div', {
    staticClass: "card-body"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t("pls-upload-proof-payment")) + " "), _c('strong', [_vm._v("RM " + _vm._s(_vm.trust.price))])]), _c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.receipt.imageUrl.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "upload-photo-2"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("upload-proof-payment")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "card rounded shadow bg-dark"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center bd-highlight",
    staticStyle: {
      "height": "150px"
    }
  }, [_vm.receipt.imageUrl ? _c('label', {
    staticStyle: {
      "border-radius": "5px",
      "margin-bottom": "0",
      "background-position": "center",
      "background-repeat": "no-repeat",
      "background-size": "cover",
      "height": "100%",
      "width": "100%"
    },
    style: 'background-image: url(' + _vm.receipt.imageUrl + ');',
    attrs: {
      "for": "upload-photo-2"
    }
  }) : _vm._e(), !_vm.receipt.imageUrl ? _c('label', {
    staticClass: "upload-photo-dark text-center",
    attrs: {
      "for": "upload-photo-2"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("select-copy")))])]) : _vm._e(), _c('input', {
    staticClass: "upload-photo-input",
    attrs: {
      "type": "file",
      "name": "photo",
      "id": "upload-photo-2",
      "accept": "image/*"
    },
    on: {
      "change": function ($event) {
        return _vm.filesChange($event.target.files, 'receipt');
      }
    }
  })])]), _vm.$v.receipt.imageUrl.$error && !_vm.$v.receipt.imageUrl.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("proof-receipt")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])])]) : _vm._e(), _vm.payment_received_at ? _c('div', {
    staticClass: "card-body"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t("payment-has-been-received-amounting-to")) + " "), _c('strong', [_vm._v("RM " + _vm._s(_vm.trustDetails.price))])]), _c('div', {
    staticClass: "row"
  }, [_vm.userRole != 'Client' ? _c('div', {
    staticClass: "col-md-6 d-flex justify-content-around"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("send-notification")) + " ")])]) : _vm._e()])]) : _vm._e()]), _vm.btnNotification ? _c('button', {
    staticClass: "btn btn-primary mb-4",
    on: {
      "click": _vm.sendVerification
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("send-grantor-verification")) + " "), _c('small', {
    staticClass: "d-block",
    staticStyle: {
      "font-size": "8pt"
    }
  }, [_vm._v(" SMS: 0148220679")]), _c('small', {
    staticClass: "d-block",
    staticStyle: {
      "font-size": "8pt"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("email")) + ": penghibah1@yahoo.com")])]), _c('img', {
    staticClass: "pl-4",
    attrs: {
      "src": "/images/icon/direct.png",
      "alt": "",
      "height": "20"
    }
  })])]) : _vm._e()]) : _vm._e()]) : _vm._e()]), _vm.displayAgreement ? _c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center mb-0"
  }, [_vm._v(_vm._s(_vm.$t("declaration-by-grantor")))]), _c('div', {
    staticClass: "box-body mt-4",
    staticStyle: {
      "font-size": "10pt"
    }
  }, [_c('b', [_vm._v(" A. " + _vm._s(_vm.$t("agree-and-acknowledge")) + ": ")]), _c('br'), _c('br'), _c('table', {
    attrs: {
      "width": "100%"
    }
  }, [_c('tbody', [_c('tr', [_c('td', {
    attrs: {
      "width": "2%",
      "valign": "top"
    }
  }, [_vm._v("a)")]), _c('td', {
    staticStyle: {
      "text-align": "justify"
    },
    attrs: {
      "width": "98%"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("acknowledgment")) + " ")])]), _c('tr', [_c('td', {
    attrs: {
      "valign": "top"
    }
  }, [_vm._v("b)")]), _c('td', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("info-changes-to-awaris")) + " ")])]), _c('tr', [_c('td', {
    attrs: {
      "valign": "top"
    }
  }, [_vm._v("c)")]), _c('td', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("asset-transfer-property")) + " ")])]), _c('tr', [_c('td', {
    attrs: {
      "valign": "top"
    }
  }, [_vm._v("d)")]), _c('td', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("free-liabilities-charges-encumbrances")) + " ")])]), _c('tr', [_c('td', {
    attrs: {
      "valign": "top"
    }
  }, [_vm._v("e)")]), _c('td', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("no-bankrupt")) + " ")])]), _c('tr', [_c('td', {
    attrs: {
      "valign": "top"
    }
  }, [_vm._v("f)")]), _c('td', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("transfer-ownership")) + " ")])]), _c('tr', [_c('td', {
    attrs: {
      "valign": "top"
    }
  }, [_vm._v("g)")]), _c('td', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("promise-inform-trustee")) + " ")])]), _c('tr', [_c('td', {
    attrs: {
      "valign": "top"
    }
  }, [_vm._v("h)")]), _c('td', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("agree-authorize-details")) + " ")])])])]), _c('br'), _c('b', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" B. " + _vm._s(_vm.$t("declaration-act")) + " ")]), _c('br'), _c('br'), _c('p', {
    staticClass: "text-justify"
  }, [_vm._v(" " + _vm._s(_vm.$t("policy-awaris")) + " ")]), _c('br'), _c('table', {
    attrs: {
      "width": "100%"
    }
  }, [_c('tbody', [_c('tr', [_c('td', {
    attrs: {
      "width": "2%",
      "valign": "top"
    }
  }, [_vm._v("a)")]), _c('td', {
    staticStyle: {
      "text-align": "justify"
    },
    attrs: {
      "width": "98%"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("confirm-establishment-trust")) + ": ")])]), _c('tr', [_c('td', {
    attrs: {
      "valign": "top"
    }
  }), _c('td', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" i) " + _vm._s(_vm.$t("criminal-activities")) + " ")])]), _c('tr', [_c('td', {
    attrs: {
      "valign": "top"
    }
  }), _c('td', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" ii) " + _vm._s(_vm.$t("law-reg-amla")) + " ")])]), _c('tr', [_c('td', {
    attrs: {
      "valign": "top"
    }
  }, [_vm._v("b)")]), _c('td', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("understand-agree-trust")) + ": ")])]), _c('tr', [_c('td', {
    attrs: {
      "valign": "top"
    }
  }), _c('td', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" i) " + _vm._s(_vm.$t("receive-distribute-criminal-act")) + " ")])]), _c('tr', [_c('td', {
    attrs: {
      "valign": "top"
    }
  }), _c('td', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" ii) " + _vm._s(_vm.$t("involve-amla")) + " ")])]), _c('tr', [_c('td', {
    attrs: {
      "valign": "top"
    }
  }, [_vm._v("c)")]), _c('td', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("understand-amla")) + " ")])])])]), _c('br'), _c('b', [_vm._v("C. " + _vm._s(_vm.$t("pdpa")))]), _c('br'), _c('br'), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("pdpa-para2")) + " ")]), _c('br'), !_vm.amendmentId || _vm.amendment && _vm.amendment.status != 0 ? _c('div', {
    staticClass: "custom-control custom-checkbox text-center"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.acceptPdpa,
      expression: "acceptPdpa"
    }],
    staticClass: "custom-control-input",
    attrs: {
      "type": "checkbox",
      "id": "customCheck1",
      "disabled": _vm.checkBoxdisable,
      "value": "1"
    },
    domProps: {
      "checked": Array.isArray(_vm.acceptPdpa) ? _vm._i(_vm.acceptPdpa, "1") > -1 : _vm.acceptPdpa
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.acceptPdpa,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "1",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.acceptPdpa = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.acceptPdpa = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.acceptPdpa = $$c;
        }
      }, _vm.acceptPdpaFunc]
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "customCheck1"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.$t("understand-declaration")))])])]) : _vm._e(), _vm.checkPdpa && !_vm.btnSetujuSign && _vm.trust ? _c('div', [_vm.trust && [0, 1].includes(_vm.trust.status) || _vm.amendmentId ? _c('div', {
    staticClass: "d-flex justify-content-center m-4"
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row"
    }
  }, [_c('v-otp-input', {
    ref: "otpInput",
    attrs: {
      "input-classes": "otp-input",
      "separator": "-",
      "num-inputs": 6,
      "should-auto-focus": true,
      "is-input-num": true
    },
    on: {
      "on-change": _vm.handleOnChange,
      "on-complete": _vm.handleOnComplete
    }
  })], 1)]) : _vm._e(), _vm.trust && [0, 1].includes(_vm.trust.status) || _vm.amendmentId ? _c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [!_vm.otpSent ? _c('b-btn', {
    staticClass: "btn-sm btn-warning m-2",
    on: {
      "click": _vm.requestOtp
    }
  }, [_vm._v(_vm._s(_vm.btnotptext))]) : _vm._e()], 1) : _vm._e(), _c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_vm.otpSent ? _c('span', [_c('small', [_vm._v(_vm._s(_vm.$t("otp-sent")))])]) : _vm._e()])]) : _vm._e(), _vm.btnSetujuSign && !_vm.amendmentId ? _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "card",
    staticStyle: {
      "width": "100%",
      "display": "inline-block"
    }
  }, [_c('div', {
    staticStyle: {
      "position": "relative",
      "height": "300px",
      "width": "100%"
    }
  }, [_c('VueSignaturePad', {
    ref: "signaturePad",
    staticStyle: {
      "position": "absolute",
      "z-index": "1"
    },
    attrs: {
      "height": "300px",
      "options": {
        onBegin: function () {
          _vm.$refs.signaturePad.resizeCanvas();
        }
      }
    }
  }), _c('label', {
    staticStyle: {
      "z-index": "0",
      "position": "absolute",
      "right": "0px",
      "left": "0px",
      "top": "45%",
      "color": "#b6b6b4"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("sign-here")) + " ")]), _c('a', {
    staticClass: "btn btn-primary",
    staticStyle: {
      "position": "absolute",
      "right": "5px",
      "top": "5px",
      "z-index": "2"
    },
    on: {
      "click": _vm.undo
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/rubber.svg",
      "height": "20",
      "alt": ""
    }
  })])], 1)])]) : _vm._e()]), _vm.amendment && _vm.trust ? _c('div', [_vm.checkBoxdisable && _vm.signature.public_path ? _c('img', {
    staticClass: "mt-3",
    staticStyle: {
      "width": "130px"
    },
    attrs: {
      "src": _vm.signature.public_path,
      "alt": "",
      "srcset": ""
    }
  }) : _vm._e(), _vm.checkBoxdisable ? _c('div', [_vm._v(" [ "), _c('strong', {
    domProps: {
      "innerHTML": _vm._s(_vm.trust.user_detail.name)
    }
  }), _vm._v("] ")]) : _vm._e()]) : _vm._e()])])]) : _vm._e(), !_vm.amendmentId && _vm.trust && [0, 1].includes(_vm.trust.status) ? _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.finish
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("done")) + " ")])])])]) : _vm._e(), _vm.amendmentId && !_vm.checkPdpa && _vm.amendment && _vm.amendment.status == 0 ? _c('div', {
    staticClass: "d-flex justify-content-around"
  }, [_c('b-button', {
    staticClass: "btn btn-primary m-1",
    on: {
      "click": _vm.backButton
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("back")) + " ")]), _c('b-button', {
    staticClass: "btn btn-primary m-1",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.openotp
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("send-application")) + " ")])], 1) : _vm._e(), _vm.amendmentId && _vm.otpSent ? _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('b-button', {
    staticClass: "btn btn-primary m-1",
    on: {
      "click": _vm.updateAmendmentStatus
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("submit")) + " ")])], 1) : _vm._e()])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }